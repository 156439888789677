<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card class="mb-8">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 xs:w-1/1 w-full mb-4"><h4>การกำหนดอัตราค่าน้ำ</h4></div>
          <div class="vx-col md:w-2/3 xs:w-1/1 w-full mb-4 text-position">
            <vs-button color="danger" type="filled" @click="cancel()" class="btn-edit mr-3">ยกเลิกการแก้ไข</vs-button>
            <vs-button color="primary" type="filled" class="btn-edit mr-3">บันทึกการแก้ไข</vs-button>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 xs:w-1/1 w-full mb-3"><span>การดำเนินการ</span>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
                <label class="text-sm opacity-75">ระบบใช้น้ำที่:</label>
                <v-select :options="dataSelected" :value="dataSelected" @input="setSelected" :clearable="false" v-model="dataSelected[0]" class="mb-4 md:mb-0"/>
              </div>
              <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
                <label class="text-sm opacity-75">ใช้งานกับ:</label>
                <vs-input class="w-full" v-model="name"/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/2 xs:w-1/1 w-full mb-3"><span>ค่าน้ำขั้นต่ำ</span>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
                <label class="text-sm opacity-75">จำนวนหน่วย:</label>
                <vs-input class="w-full" v-model="unit"/>
              </div>
              <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
                <label class="text-sm opacity-75">ค่าน้ำขั้นต่ำ:</label>
                <vs-input class="w-full" v-model="min"/>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card title="รายการมิเตอร์น้ำ">
        <vs-table :data="[]" class="vs-tabs-position-left table-unit-water">

          <template slot="thead">
            <vs-th>ค่าน้ำระดับที่</vs-th>
            <vs-th>1</vs-th>
            <vs-th>2</vs-th>
            <vs-th>3</vs-th>
            <vs-th>4</vs-th>
            <vs-th>5</vs-th>
            <vs-th>6</vs-th>
            <vs-th>7</vs-th>
            <vs-th>8</vs-th>
            <vs-th>9</vs-th>
            <vs-th>10</vs-th>
            <vs-th>11</vs-th>
            <vs-th>12</vs-th>
            <vs-th>13</vs-th>
            <vs-th>14</vs-th>
            <vs-th>15</vs-th>
            <vs-th>16</vs-th>
            <vs-th>17</vs-th>
            <vs-th>18</vs-th>
            <vs-th>19</vs-th>
            <vs-th>20</vs-th>
          </template>
          <template slot-scope="{datas}">
            <colgroup>
              <col class="th-width">
              <col span="20">
            </colgroup>
            <vs-tr>
              <vs-td>อัตราค่าน้ำจากหน่วยที่</vs-td>
              <vs-td class="p-1" v-for="(row, index) in itemRow1">
                <vs-input type="number" class="input-item tr-1" v-model="row.data" readonly/>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td>อัตราค่าน้ำถึงหน่วยที่</vs-td>
              <vs-td class="p-1" v-for="(row, index) in itemRow2">
                <vs-input v-if="index != itemRow2.length - 1" type="number" class="input-item tr-2" v-model="row.data" @change="changeRow1(row.data, index)"/>
                <vs-input v-if="index == itemRow2.length - 1" type="text" class="input-item tr-2" v-model="row.data" @change="changeRow1(row.data, index)"/>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td>อัตราค่า</vs-td>
              <vs-td class="p-1" v-for="(row, index) in itemRow3">
                <vs-input type="number" class="input-item tr-3" v-model="row.data"/>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import moduleRate from "@/store/rate/moduleRate.js"

  export default {
    components: {
      vSelect
    },
    data() {
      return {
        selected: [],
        itemsPerPage: 20,
        isMounted: false,
        index: 0,
        name: '',
        unit: '',
        min: '',
        itemRow1: [],
        itemRow2: [],
        itemRow3: [],
      }
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0
      },
      dataSelected() {
        let options = [];
        this.$store.state.rate.items.forEach(function (item, index) {
          options.push({
            'label': item.metertype_id,
            'value': item.metertype_name,
            'index': index,
            'meter_max': item.meter_max,
            'data': item.data
          })
        });
        return options;
      }
    },
    methods: {
      async init() {
        let data = await this.$store.state.rate.items[0];
        this.name = data.metertype_name;
        let word = data.meter_max.split(':');
        this.unit = word[0];
        this.min = word[1];
        let itemData = data.data;
        itemData.forEach(res => {
          this.itemRow1.push({data: res.rates});
          this.itemRow2.push({data: res.ratet});
          this.itemRow3.push({data: res.rate});
        });
      },
      setSelected(value) {
        this.index = value.index;
        this.name = value.value;
        let word = value.meter_max.split(':');
        this.unit = word[0];
        this.min = word[1];
        let itemData = value.data;
        let row1 = [];
        let row2 = [];
        let row3 = [];
        itemData.forEach(res => {
          row1.push({data: res.rates});
          row2.push({data: res.ratet});
          row3.push({data: res.rate});
        });
        this.itemRow1 = row1;
        this.itemRow2 = row2;
        this.itemRow3 = row3;
      },
      changeRow1(data, index) {
        let number = parseInt(data);
        this.itemRow1[index + 1].data = number + 1;
      },
      async cancel() {
        let data = await this.$store.state.rate.items[this.index];
        this.name = data.metertype_name;
        let word = data.meter_max.split(':');
        this.unit = word[0];
        this.min = word[1];
        let itemData = data.data;
        let row1 = [];
        let row2 = [];
        let row3 = [];
        itemData.forEach(res => {
          row1.push({data: res.rates});
          row2.push({data: res.ratet});
          row3.push({data: res.rate});
        });
        this.itemRow1 = row1;
        this.itemRow2 = row2;
        this.itemRow3 = row3;
      }
    },
    async created() {
      if (!moduleRate.isRegistered) {
        this.$store.registerModule('rate', moduleRate);
        moduleRate.isRegistered = true
      }
      await this.$store.dispatch("rate/getDataListItems");
      this.init();
    },
    mounted() {
      this.isMounted = true;
    }
  }
</script>

<style lang="scss">
  .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-export {
    padding: .75rem 3.35rem;
  }
  .text-position {
    text-align: right;
  }
  .table-unit-water .vs-con-tbody .vs-table--tbody-table {
    table-layout: fixed;
    overflow-x: scroll;
    width: 100%;
  }
  .th-width {
    width: 160px;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th:first-child .vs-table-text{
    justify-content: left;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th .vs-table-text{
    justify-content: center;
  }
  .table-unit-water .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td:first-child span{
    display: flex;
    justify-content: left;
  }
  .table-unit-water .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td span{
    display: flex;
    justify-content: center;
  }
  .input-item {
    width: 100%;
    ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
        display: none;
    }
  }
  .input-item .vs-input--input.normal {
    padding: 8px 2px !important;
    text-align: center;
  }
  .vs-table--not-data {
    display: none;
  }
  .tr-1 .vs-input--input.normal {
    background-color: lightcyan;
  }
  .tr-2 .vs-input--input.normal {
    background-color: #FFD3D8;
  }
  .tr-3 .vs-input--input.normal {
    background-color: lightyellow;
  }

  @media screen and (max-width: 420px) {
    .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-export {
      padding: .75rem 2.35rem;
    }
    .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-edit {
      padding: .75rem 1rem;
    }
    .text-position {
      text-align: center;
    }
  }

</style>
